var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import { isApp } from '../../lib/utils';
import Tabbar from '../../components/tabbar/index';
require('./styles.css');
import is from '../../lib/ua';
import { history } from '../../routes';
var HomePage = /** @class */ (function (_super) {
    __extends(HomePage, _super);
    function HomePage(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            selectedIndex: undefined
        };
        return _this;
    }
    HomePage.prototype.componentWillMount = function () {
        //this.appBar()
    };
    HomePage.prototype.componentDidMount = function () {
        var pathName = location.pathname;
        this.selectBar(pathName);
    };
    HomePage.prototype.componentWillReceiveProps = function (props) {
        var pathName = location.pathname;
        this.selectBar(pathName);
    };
    HomePage.prototype.selectBar = function (pathName) {
        if (pathName === '/') {
            this.setState({
                selectedIndex: 0
            });
        }
        else if (pathName === '/chooseindex') {
            this.setState({
                selectedIndex: 1
            });
        }
        else if (pathName === '/fundHome') {
            this.setState({
                selectedIndex: 2
            });
        }
        else if (pathName === '/favorite') {
            this.setState({
                selectedIndex: 3
            });
        }
        else if (pathName === '/me') {
            this.setState({
                selectedIndex: 4
            });
        }
        else if (pathName === '/stock-picker') {
            this.setState({
                selectedIndex: 5
            });
        }
    };
    HomePage.prototype.appBar = function () {
        var _this = this;
        if (isApp) {
            if (is.iPhone) {
                window['setupWebViewJavascriptBridge'](function (bridge) {
                    bridge.registerHandler('tabbar', function (data, responseCallback) {
                        var index = data.index;
                        sessionStorage.removeItem('groupIdf');
                        if (sessionStorage.getItem('strategyCache')) {
                            sessionStorage.removeItem('strategyCache');
                        }
                        if (index == 0) {
                            history.push('/');
                        }
                        else if (index === 1) {
                            history.push('/fundHome');
                        }
                        else if (index == 2) {
                            history.push('/favorite');
                        }
                        else if (index == 3) {
                            history.push('/chooseindex');
                        }
                        else if (index == 4) {
                            history.push('/me');
                        }
                        else if (index == 5) {
                            history.push('/stock-picker');
                        }
                        _this.setState({
                            selectedIndex: data.index
                        });
                        responseCallback(index);
                    });
                });
            }
            else {
                window['dsBridge'].register('tabbar', function (l, r) {
                    var index = JSON.parse(l).index;
                    sessionStorage.removeItem('groupIdf');
                    if (sessionStorage.getItem('strategyCache')) {
                        sessionStorage.removeItem('strategyCache');
                    }
                    if (index == 0) {
                        history.push('/');
                    }
                    else if (index === 1) {
                        history.push('/fundHome');
                    }
                    else if (index == 2) {
                        history.push('/favorite');
                    }
                    else if (index == 3) {
                        history.push('/chooseindex');
                    }
                    else if (index == 4) {
                        history.push('/me');
                    }
                    else if (index == 5) {
                        history.push('/stock-picker');
                    }
                    _this.setState({
                        selectedIndex: l.index
                    });
                });
            }
        }
    };
    HomePage.prototype.selectBarProp = function (index) {
        if (sessionStorage.getItem('strategyCache')) {
            sessionStorage.removeItem('strategyCache');
        }
        if (index == 0) {
            history.push('/');
        }
        else if (index === 1) {
            history.push('/chooseindex');
        }
        else if (index == 2) {
            history.push('/fundHome');
        }
        else if (index == 3) {
            history.push('/favorite');
        }
        else if (index == 4) {
            history.push('/me');
        }
        else if (index == 5) {
            history.push('/stock-picker');
        }
        this.setState({
            selectedIndex: index
        });
    };
    HomePage.prototype.render = function () {
        var _this = this;
        var inMyApp = navigator.userAgent.indexOf('zhishi') != -1;
        return (React.createElement("div", { className: "homepage-container" }, !inMyApp && React.createElement(Tabbar, { onselect: function (index) { return _this.selectBarProp(index); }, color: "#646D80", selectedColor: "#397BF6", selectedIndex: this.state.selectedIndex, tabs: [
                { name: '股票', icon: require('../../assets/images/market.png'), selectedIcon: require('../../assets/images/selected-market.png') },
                { name: '选股', icon: require('../../assets/images/icon-xuangu2.png'), selectedIcon: require('../../assets/images/icon-xuangu1.png') },
                { name: '基金', icon: require('../../assets/images/icon-基金-n@2x.png'), selectedIcon: require('../../assets/images/icon-基金-s@2x.png') },
                { name: '自选', icon: require('../../assets/images/favorite.png'), selectedIcon: require('../../assets/images/selected-favorite.png') },
                { name: '我的', icon: require('../../assets/images/me.png'), selectedIcon: require('../../assets/images/selected-me.png') },
            ] })));
    };
    return HomePage;
}(React.Component));
export default HomePage;
