var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as qs from "qs";
import * as detect from "mobile-detect";
import { history } from '../routes';
import dsBridge from "dsbridge";
import { isApp, upSaveDian, upSavePointer } from "./utils";
import alert from './alert/index';
import { renderApp } from '../index';
import alertNotice from './alertNotice/index';
import store from '../store';
import html2canvas from "html2canvas";
window.setupWebViewJavascriptBridge = function setupWebViewJavascriptBridge(callback) {
    if (window.WebViewJavascriptBridge) {
        return callback(WebViewJavascriptBridge);
    }
    if (window.WVJBCallbacks) {
        return window.WVJBCallbacks.push(callback);
    }
    window.WVJBCallbacks = [callback];
    var WVJBIframe = document.createElement('iframe');
    WVJBIframe.style.display = 'none';
    WVJBIframe.src = 'https://__bridge_loaded__';
    document.documentElement.appendChild(WVJBIframe);
    setTimeout(function () {
        document.documentElement.removeChild(WVJBIframe);
    }, 0);
};
function androidRun(type, data, callBack) {
    if (type == 'getToken') {
        // dsBridge.call("getToken","getToken", function (v) {
        //     if (process.env.NODE_ENV !== 'production') {
        //         localStorage.setItem('token', v);
        //         renderApp();
        //     } else {
        //         localStorage.setItem('token', v);
        //         renderApp();
        //     }
        // })
        dsBridge.register('back', function (l, r) {
            history.goBack();
        });
        dsBridge.registerAsyn('shareAddress', function (data, responseCallback) {
            return __awaiter(this, void 0, void 0, function () {
                var scrollTop, cName, stockModules, itemHights, i, isIndex, img, i, img, img;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (document.getElementById('stock') && document.getElementById('stock').parentNode.style.display != 'none') {
                                scrollTop = document.getElementById('stock').scrollTop;
                                cName = 'stockModules';
                            }
                            else if (document.getElementById('fundDetails') && document.getElementById('fundDetails').parentNode.style.display != 'none') {
                                scrollTop = document.getElementById('fundDetails').scrollTop;
                                cName = 'fundModules';
                            }
                            else if (document.getElementById('stock-index') && document.getElementById('stock-index').parentNode.style.display != 'none') {
                                scrollTop = document.getElementById('stock-index').scrollTop;
                                cName = 'stockIndexModule';
                            }
                            else if (document.getElementById('stockHK') && document.getElementById('stockHK').parentNode.style.display != 'none') {
                                scrollTop = document.getElementById('stockHK').scrollTop;
                                cName = 'stockHkModule';
                            }
                            stockModules = document.getElementsByClassName("" + cName)[0].childNodes;
                            itemHights = [];
                            for (i = 0; i < stockModules.length; i++) {
                                if (stockModules[i]) {
                                    itemHights.push({ name: stockModules[i].id.replace('s_', ''), h: stockModules[i].offsetTop });
                                }
                            }
                            if (!(itemHights && itemHights.length != 0 && itemHights != '[]')) return [3 /*break*/, 9];
                            isIndex = void 0;
                            if (!data) return [3 /*break*/, 2];
                            itemHights.map(function (e, hi) {
                                document.querySelector("." + cName + ">div:nth-child(" + (hi + 1) + ")").setAttribute('data-html2canvas-ignore', '');
                            });
                            document.querySelector("." + cName + ">div:nth-child(" + data + ")").removeAttribute('data-html2canvas-ignore');
                            return [4 /*yield*/, getDivToImg(data, cName)];
                        case 1:
                            img = _a.sent();
                            responseCallback({
                                index: data,
                                length: itemHights.length,
                                img: img,
                                name: itemHights[data - 1].name
                            });
                            return [3 /*break*/, 8];
                        case 2:
                            i = 0;
                            _a.label = 3;
                        case 3:
                            if (!(i < itemHights.length)) return [3 /*break*/, 8];
                            if (!(itemHights[i].h > scrollTop)) return [3 /*break*/, 5];
                            isIndex = i;
                            itemHights.map(function (e, hi) {
                                document.querySelector("." + cName + ">div:nth-child(" + (hi + 1) + ")").setAttribute('data-html2canvas-ignore', '');
                            });
                            document.querySelector("." + cName + ">div:nth-child(" + (isIndex + 1) + ")").removeAttribute('data-html2canvas-ignore');
                            return [4 /*yield*/, getDivToImg(i + 1, cName)];
                        case 4:
                            img = _a.sent();
                            responseCallback({
                                index: isIndex + 1,
                                length: itemHights.length,
                                img: img,
                                name: itemHights[isIndex].name
                            });
                            return [2 /*return*/];
                        case 5:
                            if (!(isIndex == undefined && i == itemHights.length - 1)) return [3 /*break*/, 7];
                            itemHights.map(function (e, hi) {
                                document.querySelector("." + cName + ">div:nth-child(" + (hi + 1) + ")").setAttribute('data-html2canvas-ignore', '');
                            });
                            document.querySelector("." + cName + ">div:nth-child(" + itemHights.length + ")").removeAttribute('data-html2canvas-ignore');
                            return [4 /*yield*/, getDivToImg(i + 1, cName)];
                        case 6:
                            img = _a.sent();
                            responseCallback({
                                index: itemHights.length,
                                length: itemHights.length,
                                img: img,
                                name: itemHights[itemHights.length - 1].name
                            });
                            return [2 /*return*/];
                        case 7:
                            i++;
                            return [3 /*break*/, 3];
                        case 8: return [3 /*break*/, 10];
                        case 9: return [2 /*return*/, ("0,0")];
                        case 10: return [2 /*return*/];
                    }
                });
            });
        });
        dsBridge.call('share_content_h', { h: data.h
        }, function (res) {
            callBack && callBack(res ? JSON.parse(res) : null);
        });
        dsBridge.register('navTo', function (l, r) {
            if (l == 'zixuan') {
                upSaveDian('stock_filter_add_to_mine', '点击添加自选股', '选股结果页面', '');
                history.push('/selectAdd');
            }
            else {
                history.push("/" + l);
            }
        });
        dsBridge.register('getToken', function (l, r) {
            window.localStorage.setItem('token', l);
            if (!l) {
                window.localStorage.removeItem('newUserGiftNotShowDay3');
                window.localStorage.removeItem('newUserGiftNotShow');
                window.sessionStorage.removeItem('strategyCache');
                window.sessionStorage.removeItem('selectParamCache');
                window.sessionStorage.removeItem('strategy');
                window.sessionStorage.removeItem('selectParam');
            }
            renderApp();
        });
    }
    if (type == 'appInfo') {
        dsBridge.call('appInfo', {}, function (res) {
            callBack && callBack(res ? JSON.parse(res) : null);
        });
    }
    if (type == 'infoToShare') {
        dsBridge.call('infoToShare', {}, function (res) {
            callBack && callBack(res ? JSON.parse(res) : null);
        });
    }
    if (type == 'goBack') {
        dsBridge.call('goback', {}, function responseCallback(responseData) {
        });
    }
    if (type == 'rightNavBar') {
        dsBridge.call("rightNavBar", {
            'hidden': data.hidden,
            'type': data.type,
            'main': data.main,
        }, function (v) {
            window.localStorage.setItem('isLiuHai', v);
        });
    }
    if (type == 'pay') {
        var routeArr = store.getState().routeLast_pathName;
        upSavePointer([
            { name: 'event_name', value: '支付' },
            { name: 'event_ext1', value: '发起支付' },
            { name: 'event_ext2', value: data.goodsId },
            { name: 'event_ext3', value: routeArr && routeArr.length > 1 ? routeArr[routeArr.length - 2] : null },
        ]);
        dsBridge.call('pay', {
            'goodsId': data.goodsId,
            'num': data.num,
            'couponId': data.couponId,
            'amount': data.amount
        }, function responseCallback(responseData) {
            callBack && callBack(responseData);
        });
    }
    if (type == 'shareContent') {
        dsBridge.call("shareContent", {
            'title': data.title,
            'content': data.content,
            'url': data.url ? data.url : '',
            'imgUrl': data.imgUrl ? data.imgUrl : '',
            'fileUrl': data.fileUrl ? data.fileUrl : ''
        }, function (v) {
        });
    }
    if (type == 'shareAction') {
        dsBridge.call('shareAction', {
            'title': data.title,
            'content': data.content,
            'url': data.url ? data.url : '',
            'imgUrl': data.imgUrl ? data.imgUrl : '',
            'fileUrl': data.fileUrl ? data.fileUrl : ''
        }, function responseCallback(responseData) {
        });
    }
    if (type == 'toFavorite') {
        dsBridge.register('toFavorite', function (l, r) {
            history.push('/favorite');
        });
    }
    if (type == 'hidden') {
        dsBridge.call("hidden", { hidden: data.hidden }, function (v) {
        });
    }
    if (type == 'navTo') {
        dsBridge.call('navTo', {
            'view': data.view,
            'alert': data.alert,
            'data': data.data
        }, function responseCallback(responseData) {
        });
        if (data.view == 'relogin') {
            setTimeout(function () {
                localStorage.removeItem('token');
            }, 500);
        }
    }
    if (type == 'showMask') {
        dsBridge.call('showMask', {
            'imgUrl': data.imgUrl,
            'src': data.src
        }, function responseCallback(responseData) {
        });
        if (data.view == 'relogin') {
            setTimeout(function () {
                localStorage.removeItem('token');
            }, 500);
        }
    }
    if (type == 'padTo') {
        var param = {};
        if (data.pdf) {
            param = { 'pdf': data.pdf, };
        }
        else {
            param = { 'url': data.url, };
        }
        dsBridge.call(padTo, param, function responseCallback(responseData) {
        });
    }
    if (type == 'router') {
        dsBridge.call("router", { view: data.view, finish: data.finish, isRefresh: data.isRefresh, }, function (v) {
        });
    }
    if (type == 'url') {
        dsBridge.call('url', { url: data.url, showUserRatings: data.showUserRatings }, function responseCallback(responseData) {
        });
    }
    if (type == 'getCode') {
        dsBridge.call("getCode", {
            'code': data.code,
            'count': data.count,
            'index': data.index,
            'ipoBoard': data.ipoBoard
        }, function (v) {
        });
    }
    if (type == 'switchCode') {
        dsBridge.register('switchCode', function (l, r) {
            if (Number(JSON.parse(l).index) == 1) {
                previous();
            }
            else if (Number(JSON.parse(l).index) == -1) {
                nextCode(JSON.parse(l).type, JSON.parse(l).stockCode, JSON.parse(l).i);
            }
            else {
                next();
            }
        });
    }
    if (type == 'StockRealTime') {
        dsBridge.call('StockRealTime', {
            'data': data.data,
        });
    }
}
/*
:
 */
export function iosAndroid(type, data, callback) {
    return __awaiter(this, void 0, void 0, function () {
        var md, isAndroid;
        return __generator(this, function (_a) {
            if (!isApp)
                return [2 /*return*/];
            md = new detect(window.navigator.userAgent);
            if (md.os()) {
                isAndroid = md.os().indexOf('Android') != -1;
            }
            try {
                if (isAndroid) {
                    androidRun(type, data, callback);
                }
                else {
                    window.setupWebViewJavascriptBridge(function (bridge) {
                        if (type == 'rightNavBar') {
                            bridge.callHandler('rightNavBar', {
                                'type': data.type,
                                'main': data.main,
                                'hidden': data.hidden
                            }, function responseCallback(responseData) {
                                window.localStorage.setItem('isLiuHai', responseData.liuHai);
                            });
                        }
                        if (type == 'goBack') {
                            bridge.callHandler('goback', {}, function responseCallback(responseData) {
                            });
                        }
                        if (type == 'shareContent') {
                            if (data.url) {
                                bridge.callHandler('shareContent', {
                                    'title': data.title,
                                    'content': data.content,
                                    'url': data.url,
                                    'imgUrl': data.imgUrl ? data.imgUrl : '',
                                    'fileUrl': data.fileUrl ? data.fileUrl : ''
                                }, function responseCallback(responseData) {
                                });
                            }
                            else {
                                bridge.callHandler('shareContent', {
                                    'title': data.title,
                                    'content': data.content,
                                    'imgUrl': data.imgUrl ? data.imgUrl : ''
                                }, function responseCallback(responseData) {
                                });
                            }
                        }
                        if (type == 'shareAction') {
                            bridge.callHandler('shareAction', {
                                'title': data.title,
                                'content': data.content,
                                'url': data.url ? data.url : '',
                                'imgUrl': data.imgUrl ? data.imgUrl : '',
                                'fileUrl': data.fileUrl ? data.fileUrl : ''
                            }, function responseCallback(responseData) {
                            });
                        }
                        if (type == 'appInfo') {
                            bridge.callHandler('appInfo', {}, function (res) {
                                callback && callback(res);
                            });
                        }
                        if (type == 'url') {
                            bridge.callHandler('url', { url: data.url, showUserRatings: data.showUserRatings }, function responseCallback(responseData) {
                            });
                        }
                        if (type == 'hidden') {
                            bridge.callHandler('hidden', { hidden: data.hidden }, function responseCallback(responseData) {
                            });
                        }
                        if (type == 'back') {
                        }
                        if (type == 'getToken') {
                            bridge.registerHandler('toFavorite', function (data, responseCallback) {
                                history.push('/favorite');
                            });
                            bridge.callHandler('share_content_h', { h: data.h }, function responseCallback(responseData) {
                            });
                            bridge.registerHandler('shareAddress', function (data, responseCallback) {
                                return __awaiter(this, void 0, void 0, function () {
                                    var scrollTop, cName, stockModules, itemHights, i, isIndex, img, i, img, img;
                                    return __generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0:
                                                if (document.getElementById('stock') && document.getElementById('stock').parentNode.style.display != 'none') {
                                                    scrollTop = document.getElementById('stock').scrollTop;
                                                    cName = 'stockModules';
                                                }
                                                else if (document.getElementById('fundDetails') && document.getElementById('fundDetails').parentNode.style.display != 'none') {
                                                    scrollTop = document.getElementById('fundDetails').scrollTop;
                                                    cName = 'fundModules';
                                                }
                                                else if (document.getElementById('stock-index') && document.getElementById('stock-index').parentNode.style.display != 'none') {
                                                    scrollTop = document.getElementById('stock-index').scrollTop;
                                                    cName = 'stockIndexModule';
                                                }
                                                else if (document.getElementById('stockHK') && document.getElementById('stockHK').parentNode.style.display != 'none') {
                                                    scrollTop = document.getElementById('stockHK').scrollTop;
                                                    cName = 'stockHkModule';
                                                }
                                                stockModules = document.getElementsByClassName("" + cName)[0].childNodes;
                                                itemHights = [];
                                                for (i = 0; i < stockModules.length; i++) {
                                                    if (stockModules[i]) {
                                                        itemHights.push({ name: stockModules[i].id.replace('s_', ''), h: stockModules[i].offsetTop });
                                                    }
                                                }
                                                if (!(itemHights && itemHights.length != 0 && itemHights != '[]')) return [3 /*break*/, 9];
                                                isIndex = void 0;
                                                if (!data) return [3 /*break*/, 2];
                                                itemHights.map(function (e, hi) {
                                                    document.querySelector("." + cName + ">div:nth-child(" + (hi + 1) + ")").setAttribute('data-html2canvas-ignore', '');
                                                });
                                                document.querySelector("." + cName + ">div:nth-child(" + data + ")").removeAttribute('data-html2canvas-ignore');
                                                return [4 /*yield*/, getDivToImg(data, cName)];
                                            case 1:
                                                img = _a.sent();
                                                responseCallback({
                                                    index: data,
                                                    length: itemHights.length,
                                                    img: img,
                                                    name: itemHights[data - 1].name
                                                });
                                                return [3 /*break*/, 8];
                                            case 2:
                                                i = 0;
                                                _a.label = 3;
                                            case 3:
                                                if (!(i < itemHights.length)) return [3 /*break*/, 8];
                                                if (!(itemHights[i].h > scrollTop)) return [3 /*break*/, 5];
                                                isIndex = i;
                                                itemHights.map(function (e, hi) {
                                                    document.querySelector("." + cName + ">div:nth-child(" + (hi + 1) + ")").setAttribute('data-html2canvas-ignore', '');
                                                });
                                                document.querySelector("." + cName + ">div:nth-child(" + (isIndex + 1) + ")").removeAttribute('data-html2canvas-ignore');
                                                return [4 /*yield*/, getDivToImg(isIndex + 1, cName)];
                                            case 4:
                                                img = _a.sent();
                                                responseCallback({
                                                    index: isIndex + 1,
                                                    length: itemHights.length,
                                                    img: img,
                                                    name: itemHights[isIndex].name
                                                });
                                                return [3 /*break*/, 8];
                                            case 5:
                                                if (!(isIndex == undefined && i == itemHights.length - 1)) return [3 /*break*/, 7];
                                                itemHights.map(function (e, hi) {
                                                    document.querySelector("." + cName + ">div:nth-child(" + (hi + 1) + ")").setAttribute('data-html2canvas-ignore', '');
                                                });
                                                document.querySelector("." + cName + ">div:nth-child(" + itemHights.length + ")").removeAttribute('data-html2canvas-ignore');
                                                return [4 /*yield*/, getDivToImg(itemHights.length, cName)];
                                            case 6:
                                                img = _a.sent();
                                                responseCallback({
                                                    index: itemHights.length,
                                                    length: itemHights.length,
                                                    img: img,
                                                    name: itemHights[itemHights.length - 1].name
                                                });
                                                _a.label = 7;
                                            case 7:
                                                i++;
                                                return [3 /*break*/, 3];
                                            case 8: return [3 /*break*/, 10];
                                            case 9:
                                                responseCallback("0,0");
                                                _a.label = 10;
                                            case 10: return [2 /*return*/];
                                        }
                                    });
                                });
                            });
                            bridge.registerHandler('back', function (data, responseCallback) {
                                history.goBack();
                            });
                            bridge.registerHandler('getToken', function (data, responseCallback) {
                                if (!data.token) {
                                    window.localStorage.removeItem('newUserGiftNotShowDay3');
                                    window.localStorage.removeItem('newUserGiftNotShow');
                                    window.sessionStorage.removeItem('strategyCache');
                                    window.sessionStorage.removeItem('selectParamCache');
                                    window.sessionStorage.removeItem('strategy');
                                    window.sessionStorage.removeItem('selectParam');
                                }
                                localStorage.setItem('token', data.token);
                                responseCallback("ok");
                                renderApp();
                            });
                            bridge.registerHandler('navTo', function (data, responseCallback) {
                                if (data == 'zixuan') {
                                    upSaveDian('stock_filter_add_to_mine', '点击添加自选股', '选股结果页面', '');
                                    history.push('/selectAdd');
                                }
                                else {
                                    history.push("/" + data);
                                }
                            });
                        }
                        if (type == 'ios_pay') {
                            var routeArr = store.getState().routeLast_pathName;
                            upSavePointer([
                                { name: 'event_name', value: '支付' },
                                { name: 'event_ext1', value: '发起支付' },
                                { name: 'event_ext2', value: data.code },
                                { name: 'event_ext3', value: routeArr && routeArr.length > 1 ? routeArr[routeArr.length - 2] : null },
                            ]);
                            bridge.callHandler('ios_pay', {
                                'code': data.code
                            }, function responseCallback(responseData) {
                                if (responseData.isSuccess == '0' && responseData.result != '用户取消购买') {
                                    alertNotice(responseData.result);
                                }
                                callback && callback(responseData);
                            });
                        }
                        if (type == 'getCode') {
                            bridge.callHandler('getCode', {
                                'code': data.code,
                                'count': data.count,
                                'index': data.index,
                                'ipoBoard': data.ipoBoard
                            }, function responseCallback(responseData) {
                            });
                        }
                        if (type == 'navTo') {
                            bridge.callHandler('navTo', {
                                'view': data.view,
                                'alert': data.alert,
                                'data': data.data
                            }, function responseCallback(responseData) {
                            });
                            if (data.view == 'relogin') {
                                setTimeout(function () {
                                    localStorage.removeItem('token');
                                }, 500);
                            }
                        }
                        if (type == 'padTo') {
                            var param = {};
                            if (data.pdf) {
                                param = { 'pdf': data.pdf, };
                            }
                            else {
                                param = { 'url': data.url, };
                            }
                            bridge.callHandler('padTo', param, function responseCallback(responseData) {
                            });
                        }
                        if (type == 'switchCode') {
                            bridge.registerHandler('switchCode', function (data, responseCallback) {
                                if (data.index == 1) {
                                    previous();
                                }
                                else if (data.index == -1) {
                                    nextCode(data.type, data.stockCode, data.i);
                                }
                                else {
                                    next();
                                }
                            });
                        }
                        if (type == 'StockRealTime') {
                            bridge.callHandler('StockRealTime', {
                                'data': data.data,
                            });
                        }
                    });
                }
            }
            catch (e) {
                alert('error');
            }
            return [2 /*return*/];
        });
    });
}
var stockType;
(function (stockType) {
    stockType["index_hk_hs"] = "index_hk_hs";
    stockType["capital"] = "capital";
    stockType["hk"] = "hk";
    stockType["index_hs"] = "index_hs";
    stockType["index_hy"] = "index_hy";
    stockType["fund_outside"] = "fund_outside";
    stockType["fund_onside"] = "fund_onside";
    stockType["fund_lof"] = "fund_lof";
    stockType["index_gn"] = "index_gn";
})(stockType || (stockType = {}));
;
function nextCode(type, stockCode, i) {
    try {
        swichStockType(type, stockCode, i);
    }
    catch (error) {
        console.error(error);
    }
}
function next() {
    try {
        var query = qs.parse(window.location.search, { ignoreQueryPrefix: true });
        var stocks = JSON.parse(sessionStorage.getItem('stocks'));
        var i = Number(query.i);
        if (i == stocks.length - 1) {
            i = -1;
        }
        var stockCode = stocks[i + 1].code ? stocks[i + 1].code : stocks[i + 1][0];
        var type = stocks[i + 1].capitalOrIndex ? stocks[i + 1].capitalOrIndex : stocks[i + 1][3];
        swichStockType(type, stockCode, i + 1);
    }
    catch (error) {
        console.error(error);
    }
}
function previous() {
    try {
        var query = qs.parse(window.location.search, { ignoreQueryPrefix: true });
        var i = query.i;
        var stocks = JSON.parse(sessionStorage.getItem('stocks'));
        if (i == 0) {
            i = stocks.length;
        }
        var stockCode = stocks[i - 1].code ? stocks[i - 1].code : stocks[i - 1][0];
        var type = stocks[i - 1].capitalOrIndex ? stocks[i - 1].capitalOrIndex : stocks[i - 1][3];
        swichStockType(type, stockCode, i - 1);
    }
    catch (error) {
        console.error(error);
    }
}
function getDivToImg(num, cName) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, new Promise(function (resolve, reject) {
                    html2canvas(document.querySelector("." + cName + ">div:nth-child(" + num + ")"), {
                        allowTaint: false,
                        useCORS: true,
                        width: window.screen.width,
                        height: document.querySelector("." + cName + ">div:nth-child(" + num + ")").clientHeight,
                        removeContainer: true
                    }).then(function (canvas) {
                        resolve(canvas.toDataURL("image/png"));
                    });
                })];
        });
    });
}
function swichStockType(type, stockCode, i) {
    var pageType = 0;
    switch (type) {
        case stockType.capital:
            pageType = 1;
            window.location.href = window.location.origin + "/stock?stockCode=" + stockCode + "&type=" + type + "&i=" + i;
            break;
        case stockType.index_hs:
            pageType = 3;
            window.location.href = window.location.origin + "/indexStock?stockCode=" + stockCode + "&type=" + type + "&i=" + i;
            break;
        case stockType.index_hy:
            pageType = 3;
            window.location.href = window.location.origin + "/indexStock?stockCode=" + stockCode + "&type=" + type + "&i=" + i;
            break;
        case stockType.index_hk_hs:
            pageType = 3;
            window.location.href = window.location.origin + "/indexStock?stockCode=" + stockCode + "&type=" + type + "&i=" + i;
            break;
        case stockType.index_gn:
            pageType = 3;
            window.location.href = window.location.origin + "/indexStock?stockCode=" + stockCode + "&type=" + type + "&i=" + i;
            break;
        case stockType.hk:
            pageType = 2;
            window.location.href = window.location.origin + "/stockHk?stockCode=" + stockCode + "&type=" + type + "&i=" + i;
            break;
        case stockType.fund_outside:
            pageType = 4;
            window.location.href = window.location.origin + "/fundDetails?code=" + stockCode + "&type=" + type + "&i=" + i;
            break;
        case stockType.fund_onside:
            pageType = 4;
            window.location.href = window.location.origin + "/fundDetails?code=" + stockCode + "&type=" + type + "&i=" + i;
            break;
        case stockType.fund_lof:
            pageType = 4;
            window.location.href = window.location.origin + "/fundDetails?code=" + stockCode + "&type=" + type + "&i=" + i;
            break;
    }
}
